import * as React from 'react';
import { Component } from 'react';
import Layout from '../../components/global/layout.js';
import Content from '../../components/callen-lorde/content.js';

class CallenLorde extends Component {
    render() {
        return (
            <>
                <Layout section='deeper-dive' page='callen-lorde'>
                    <Content section='deeper-dive' page='callen-lorde' />
                </Layout>
            </>
        );
    }
}

export default CallenLorde;
